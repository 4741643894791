<ng-container *ngIf="isLoading === false ; else loadTemplate">

  <ng-container *ngIf="this.items?.length > 0 ; else  noItemTemplate">

    <div class="create-packaging-dialog-cntr">
      <ng-container *ngIf="step1">
        <h1 mat-dialog-title>Stai per creare il tuo packaging</h1>
        <h4 mat-dialog-subtitle>Seleziona gli articoli che preferisci</h4>
        <div mat-dialog-content>
          <p-table [value]="items" [(selection)]="selectedItems" dataKey="ItemCode" [paginator]="true"
          [rows]="10">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                <th>Codice</th>
                <th>Nome</th>
                <th>Quantità</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>
                  <p-tableCheckbox [value]="item" />
                </td>
                <td>{{ item.ItemCode }}</td>
                <td>{{ item.ItemName }}</td>
                <td>{{ item.Stock }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick(1)">Chiudi</p-button>
          <p-button [disabled]="selectedItems.length < 1" pRipple (click)="submit(1)">Avanti</p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step2">
       <div class="item-cntr">
          <strong>Articolo </strong>
          <strong>Seleziona quantità </strong>
        </div>

        <form [formGroup]="itemForm" (ngSubmit)="onSubmit()">

          <div formArrayName="itemsArray">
            <div *ngFor="let control of itemsArray.controls; let i = index" [formGroupName]="i" class="item-cntr">
              <label>{{ this.selectedItemConfiguration.itemHandlings[i].Item.Name }} - {{ this.selectedItemConfiguration.itemHandlings[i].Item.Code }}</label>
              <input pInputText type="number" formControlName="qty" placeholder="Inserisci QTY" style="max-width: 100px;" />
            </div>
          </div>
        </form>

        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick(2)">Indietro</p-button>
          <p-button pRipple (click)="submit(2)">Avanti</p-button>
        </div>

      </ng-container>

      <ng-container *ngIf="step3">
        <h4 mat-dialog-subtitle>
          Seleziona il packaging a cui vuoi inserire gli articoli o creane uno
          nuovo.
        </h4>

        <div class="item-cntr" style="align-items: start;" *ngIf="packagies" [ngClass]="openDrop ? 'open' : 'close'">
          <!-- <p-dropdown style="width: 70%" [options]="packagies" [(ngModel)]="selectedPack"
            optionLabel="Name"  [filter]="true" filterBy="Name" ></p-dropdown> -->
          <form [formGroup]="packagingForm">
              <p-dropdown
                  formControlName="packagingControl"
                  [options]="packagies"
                  optionLabel="Name"  [filter]="true" filterBy="Name" optionValue="Oid" (onShow)="openDrop = !openDrop" (onHide)="openDrop = !openDrop"
                  />
          </form>
          <p-button label="Crea" icon="pi pi-plus" iconPos="right" (click)="createPackaging()"></p-button>
        </div>

        <div mat-dialog-actions class="button-cntr">
          <p-button severity="danger" (click)="onNoClick(3)">Indietro</p-button>
          <p-button pRipple (click)="submit(3)">Avanti</p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step4">
        <h4 mat-dialog-subtitle>
          <strong> Riepilogo finale </strong> <br> <br>
          Qui trovi il packaging che hai selezionato e la lista completa degli articoli con le relative quantità.
          <br>
        </h4>

        
          <div *ngIf="this.getCurrentPackaging()" class="item-cntr" style="margin-bottom: 20px;">
        Packaging: <strong>{{this.getCurrentPackaging()}}</strong>
          </div>

          <div *ngFor="let control of itemsArray.controls; let i = index" class="item-cntr" style="margin-top: 10px;">
            Articolo: <label><strong>{{ this.selectedItemConfiguration.itemHandlings[i].Item.Name }} - {{ this.selectedItemConfiguration.itemHandlings[i].Item.Code }} </strong><span style="margin-left: 15px;">Quantità: </span> <strong>{{ this.selectedItemConfiguration.itemHandlings[i].StartQty }}</strong></label>
          </div>

        <div mat-dialog-actions class="button-cntr" style="margin-top: 20px;">
          <p-button severity="danger" (click)="onNoClick(4)">Indietro</p-button>
          <p-button  severity="danger" (click)="createHandling()">Salva</p-button>
        </div>
      </ng-container>

      <ng-container *ngIf="step5">
        <h4 mat-dialog-subtitle>
          <strong> Salvataggio avvenuto con successo.</strong>
        </h4>

        <div mat-dialog-actions class="button-cntr" style="margin-top: 20px;">
          <p-button  severity="danger" (click)="onNoClick(5)">Chiudi</p-button>
        </div>
      </ng-container>
    </div>

  </ng-container>

  <ng-template #noItemTemplate>

    <div class="create-packaging-dialog-cntr" style="min-height: 150px;">

      <h4 mat-dialog-title> Mi dispiace, nessun item in stock per poter creare un packaging.</h4>

      <div mat-dialog-actions class="button-cntr">
        <p-button severity="danger" (click)="onNoClick(1)">Chiudi</p-button>
      </div>

    </div>

  </ng-template>
</ng-container>


<ng-template #loadTemplate>

  <h1 mat-dialog-title>Caricamento...</h1>

</ng-template>
