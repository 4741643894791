import { Component} from '@angular/core';
import { CtButtonConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CTGeneralService, CtGenericDialogComponent, CtGenericDialogConfiguration, CtModelConfiguration, CtModelRouteData, CtModelType, CtSelectControlOptions, CtSelectControlValue, CtThemeTypes, MAT_RAISED_ACCENT, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { FormBuilder } from '@angular/forms';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { ActivatedRoute } from '@angular/router';
import { FormGeneratorDataRouteData } from '../../../_core/route-data/form-generator-data.route-data';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-form-generator-data-edit',
  templateUrl: './form-generator-data-edit.component.html',
  styleUrls: ['./form-generator-data-edit.component.scss']
})
export class FormGeneratorDataEditComponent {

  configuration: CtFormConfiguration;

  private emailControl = this.controlService.getDefaultConfiguration({
    name: 'Email',
    type: CtControlTypes.EMAIL,
    flexSpacePercentage : 50
  }).setTheme(CtThemeTypes.MATERIAL);
  private phoneControl = this.general.getTextControl({
    name: 'Phone',
    flexSpacePercentage : 50
  }).setTheme(CtThemeTypes.MATERIAL);
  private nominativeControl = this.general.getTextControl({
    name: 'Nominative',
    flexSpacePercentage : 100
  }).setTheme(CtThemeTypes.MATERIAL);

  private StatusControl = this.controlService.getDefaultConfiguration({
    name: 'Status',
    type: CtControlTypes.ENUMERABLE,
    flexSpacePercentage: 100,
    required: true
  })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
    })
    .setValueOptions([
      CtSelectControlValue.create().setLabel('ToValidate').setValue(FormGeneratorDataStatusVO.DaValidare),
      CtSelectControlValue.create().setLabel('Validated').setValue(FormGeneratorDataStatusVO.Validato),
      CtSelectControlValue.create().setLabel('Cancelled').setValue(FormGeneratorDataStatusVO.Cancellata),
      CtSelectControlValue.create().setLabel('Refused').setValue(FormGeneratorDataStatusVO.Rifiutata),
    ])

  private controls = [

    this.emailControl,
    this.phoneControl,
    this.nominativeControl,
    this.StatusControl,


  ];

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_ACCENT);

    dataSource : FormGeneratorData | null = null;

  constructor(
    private CTGeneralService: CTGeneralService,
    private general: CTMGeneralService,
    private formBuilder: FormBuilder,
    private controlService: CtControlService,
    private route : ActivatedRoute,
    private dialog: MatLegacyDialog,

  ) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

  }

  ngOnInit() {

    const pId = this.route.snapshot.paramMap.get("id");
    if(pId == null) return;

    this.general.getInfos(
      {
        controller : 'FormGeneratorData',
        oid : +pId
      }
    ).subscribe(result => {

      if(result.Result)
        this.setup(result.Result)

    })

  }

  setup(value: any) {

    this.configuration.form?.patchValue(value.DataSource);
    this.dataSource = value.DataSource;

  }

  async submit() {

    this.configuration.form?.markAllAsTouched();

    if(this.configuration.form?.valid) {

      const pId = this.route.snapshot.paramMap.get("id");
      if(pId == null) return;

      const data : FormGeneratorData = this.configuration.form.value;
      data.Content = this.dataSource?.Content;
      if(data.Status != this.dataSource?.Status && (data.Status == FormGeneratorDataStatusVO.Validato || data.Status == FormGeneratorDataStatusVO.Rifiutata)) {
        const ret = await new Promise<boolean>((resolve) => {

          this
            .dialog
            .open(CtGenericDialogComponent, {
              data: {
                configuration:
                  CtGenericDialogConfiguration.create()
                    .setContent("warningFormData")
                    .setTitle("warning")
                    .setCancelLabel("CT_PAGE.CT_FORM.cancel")
                    .setSubmitLabel("confirm"),
              }, maxWidth: '500px', minWidth: '300px' , disableClose : true
            },
          )
            .afterClosed()
            .subscribe(result => resolve(result))

        });

        if(ret == false) return;
      }

      const model : CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(FormGeneratorDataRouteData().setId(+pId));

      this.general.putData(model, data)?.then(() => this.CTGeneralService.back());

    }


  }

}


export enum FormGeneratorDataStatusVO {
  DaValidare = 1,
  Validato = 5,
  Cancellata = 9,
  Rifiutata = 10
}

export interface FormGeneratorData{

  Email : string,
  Phone : string,
  Nominative : string,
  Status : FormGeneratorDataStatusVO
  Content : any;

}
